import { graphql, Link } from "gatsby"
import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.nodes
  const postElements = posts.map(p => (
    <div key={p.id} className="w-full sm:w-1/2 p-3 sm:p-6">
      <div className="flex flex-col gap-4 bg-white rounded-lg p-6 h-full">
        <p className="font-semibold">{p.frontmatter.category}</p>
        <Link
          to={p.fields.slug}
          target="_blank"
          className="text-3xl font-bold hover:text-[#0cbe5c] text-[#3f855e] cursor-pointer"
        >
          {p.frontmatter.title}
        </Link>
        <p className="text-base">{p.frontmatter.description}</p>
        <p>{p.frontmatter.date}</p>
      </div>
    </div>
  ))
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Blog" />
      <div className="flex flex-col items-center justify-start h-full p-8 sm:p-32 gap-4 ">
        <h1 className="font-ptsans font-black text-5xl">
          Check out our latest articles
        </h1>
        <p className="text-2xl">Learn more about programming.</p>
        <div className="flex flex-col sm:flex-row flex-wrap text-black mt-8 sm:mt-16">
          {postElements}
        </div>
      </div>
    </Layout>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          description
          title
          category
        }
        id
        fields {
          slug
        }
      }
    }
  }
`
